/**
 * Component classes registered by plugins.
 *
 */

@import "print.css";

@import "tables.css";

@import "prism.css";

@tailwind components;
